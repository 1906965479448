<template>
    <b-table-simple responsive fixed>
        <thead>
            <tr>
                <th width="3%">
                    <Check 
                        v-model="masterCheck" 
                        @change="checked => $emit('handlerMasterSelect', checked)"
                    />
                </th>
                <th><Ellipsis>Fábrica</Ellipsis></th>
                <th><Ellipsis>Cód. simpro</Ellipsis></th>
                <th><Ellipsis>Cód. usuario</Ellipsis></th>
                <th><Ellipsis>Cód. fração</Ellipsis></th>
                <th><Ellipsis>Descrição</Ellipsis></th>
                <th><Ellipsis>Vigência</Ellipsis></th>
                <th><Ellipsis>Tipo embal</Ellipsis></th>
                <th><Ellipsis>Tipo fracao</Ellipsis></th>
                <th><Ellipsis>Validade ANVISA</Ellipsis></th>
                <th><Ellipsis>Cód TUSS</Ellipsis></th>
                <th></th>
            </tr>
        </thead>
        <tbody>
            <tr v-for="(simpro, index) in simpros" :key="index">
                <td>
                    <Check 
                        :value="selecteds.includes(simpro.id)" 
                        @input="checked => !checked && masterCheck ? (masterCheck = false) : null"
                        @change="$emit('handlerSelect', simpro)" 
                    />
                </td>
                <td>{{ simpro.fabrica }}</td>
                <td>{{ simpro.cd_simpro }}</td>
                <td>{{ simpro.cd_usuario }}</td>
                <td>{{ simpro.cd_fracao }}</td>
                <td>{{ simpro.descricao }}</td>
                <td>{{ parseDate(simpro.vigencia) }}</td>
                <td>{{ simpro.tp_embal }}</td>
                <td>{{ simpro.tp_fracao }}</td>
                <td>{{ parseDate(simpro.validade_anvisa) }}</td>
                <td>{{ simpro.cd_tuss }}</td>
                <td>
                    <div class="actions">
                        <a :href="`/matmed/exceptions/${tableId}/simpro/${simpro.id}`">
                            <Exceptions 
                                class="icon" 
                                v-b-tooltip.hover title="Ver exceções"
                            />
                        </a>
                        <a :href="`/tables/${tableId}/matmed/${simpro.id}`">
                            <Update 
                                class="icon" 
                                v-b-tooltip.hover title="Ver atualizações"
                            />
                        </a>
                    </div>
                </td>
            </tr>
        </tbody>
    </b-table-simple>
</template>

<script>
import { parseCDType } from '@itemOtherExpenseProps/utils/constants'

export default {
    components: {
        Ellipsis: () => import('@/components/General/Ellipsis'),   
        Check: () => import('@/components/General/Check'),
        Exceptions: () => import('@matMed/assets/icons/exceptions.svg'),
        Update: () => import('@matMed/assets/icons/update.svg'),
    },
    props: {
        tableId: String,
        simpros: {
            type: Array,
            default: []
        },
        selecteds: {
            type: Array,
            default: []
        }
    },
    data() {
        return {
            masterCheck: false
        }
    },
    methods: {
        parseCDType,
        parseDate(date) {
            return this.moment(date).format('DD/MM/YYYY')
        },
        deleteItem(simpro) {
            this.$swal({
                icon: 'question',
                html: `<div class="body-alert">Deseja realmente excluir o Item <span>${simpro?.descricao}</span>?</div>`,
                showCancelButton: true,
                confirmButtonColor: '#F63220',
                confirmButtonText: 'Confirmar',
                cancelButtonText: 'Cancelar',
                reverseButtons: true
            }).then(res => {
                if (res.isConfirmed) {
                    this.$emit('delete', simpro)
                }
            })
        }
    }
}
</script>

<style lang="scss" scoped>
@import '@/assets/scss/table.scss';
.icon {
    width: 24px;
    height: 24px;
    cursor: pointer;
}

</style>